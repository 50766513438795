<template>
  <div>
    <header-top-dashboard primary>
      <v-row class="mt-0">
        <v-col
          v-for="(item, i) in miniCards"
          :key="i"
          cols="12"
          lg="3"
          md="6"
          class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <card-get-started></card-get-started>
          <members-card-search></members-card-search>
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Latest Messages
              </p>
            </div>

            <v-card-text
              class="card-padding card-border-bottom list-item-hover-active"
              v-for="item in messages"
              :key="item.name"
            >
              <v-row>
                <v-col>
                  <v-avatar size="24" rounded class="me-3">
                    <v-img :src="item.img"></v-img>
                  </v-avatar>
                  <span class="font-weight-600 text-typo">{{ item.name }}</span>
                </v-col>
                <v-col class="text-right">
                  <span class="text-body">{{ item.time }}</span>
                </v-col>
              </v-row>

              <h4 class="text-h4 font-weight-600 text-typo mt-4 mb-2">
                <span class="text-info" v-if="item.name === 'Mike'">●</span>
                {{ item.title }}
              </h4>
              <p class="text-body-2 ls-0 font-weight-thin text-body">
                Doasdnec id elit non mi porta gravida at eget metus. Maecenas
                sed diam eget risus varius blandit.
              </p>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow bg-gradient-primary mb-6">
            <span></span>
            <div class="card-padding">
              <v-row>
                <v-col>
                  <v-img
                    src="@/assets/img/cards/mastercard.png"
                    max-width="48"
                    height="30"
                  ></v-img>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <span
                    class="text-white font-weight-600 text-caption ls-0 me-5"
                    >Make default</span
                  >
                  <v-switch
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch"
                    v-model="switche"
                    hide-details
                    color="white"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
            <v-card-text class="card-padding card-border-bottom pt-0">
              <v-text-field
                hide-details
                outlined
                background-color="rgba(50,76,221,.5)"
                color="#fff"
                light
                placeholder="Name on card"
                class="font-size-input placeholder-white text-color-white input-alternative input-focused-alternative input-icon mb-6"
              >
                <template slot="prepend-inner">
                  <v-icon color="#fff" size=".875rem">ni ni-single-02</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                hide-details
                outlined
                background-color="rgba(50,76,221,.5)"
                color="#fff"
                light
                placeholder="Card number"
                class="font-size-input placeholder-white text-color-white input-alternative input-focused-alternative input-icon mb-6"
              >
                <template slot="prepend-inner">
                  <v-icon color="#fff" size=".875rem">ni ni-credit-card</v-icon>
                </template>
              </v-text-field>

              <v-row>
                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    background-color="rgba(50,76,221,.5)"
                    color="#fff"
                    light
                    placeholder="MM/YY"
                    class="font-size-input placeholder-white text-color-white input-alternative input-focused-alternative mb-6"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    background-color="rgba(50,76,221,.5)"
                    color="#fff"
                    light
                    placeholder="CCV"
                    class="font-size-input placeholder-white text-color-white input-alternative input-focused-alternative mb-6"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#fff" size=".875rem"
                        >ni ni-lock-circle-open</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-btn
                block
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-info py-3 px-6 rounded-sm"
                color="#11cdef"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-card class="card-shadow widget-calendar mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-muted text-uppercase text-h5">
                {{ new Date().getFullYear() }}
              </div>
              <p class="font-weight-600 text-h2 text-typo mb-0">
                {{ new Date().getDate() }}
                {{ new Date().toLocaleString("default", { month: "short" }) }}
              </p>
            </div>

            <v-card-text class="card-padding card-border-bottom">
              <full-calendar
                :options="calendarOptions"
                ref="fullCalendar"
              ></full-calendar>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h2 text-typo mb-0">
                Latest Notifications
              </p>
            </div>

            <v-card-text class="card-padding pb-0 card-border-bottom">
              <v-timeline dense align-top class="timeline-line-color">
                <v-timeline-item
                  v-for="(item, i) in timeline"
                  :key="item.title + i"
                  small
                  class="timeline"
                >
                  <template v-slot:icon>
                    <v-avatar size="33" :color="item.color">
                      <v-icon :color="item.iconColor" size="16">{{
                        item.icon
                      }}</v-icon>
                    </v-avatar>
                  </template>

                  <v-card width="480" class="mb-5">
                    <v-card-text class="pa-0">
                      <h5 class="text-h4 text-muted font-weight-600 mb-0">
                        <v-row>
                          <v-col>
                            {{ item.title }}
                          </v-col>
                          <v-col class="text-right">
                            <span class="text-muted text-caption ls-0">
                              <v-icon class="me-1 text-muted"
                                >fas fa-clock</v-icon
                              >
                              2 hrs ago
                            </span>
                          </v-col>
                        </v-row>
                      </h5>
                      <p class="mt-1 mb-0 text-typo font-weight-600">
                        {{ item.description }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>

          <progress-track></progress-track>

          <v-card class="card-shadow card-padding my-6">
            <v-card-text class="px-0 py-0">
              <v-row>
                <v-col>
                  <v-img
                    src="@/assets/img/cards/paypal.png"
                    width="48"
                    height="30"
                  ></v-img>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    elevation="0"
                    x-small
                    :ripple="false"
                    class="text-uppercase badge-success ls-0 font-weight-600"
                    min-width="64"
                    height="28"
                    color="#b0eed3"
                    >Active</v-btn
                  >
                </v-col>
              </v-row>
              <span
                class="font-weight-600 text-h6 text-uppercase text-muted ls-2 mt-8 d-block"
                >Paypal email</span
              >
              <div class="text-h1 text-typo font-weight-600">@johnsnow</div>

              <span
                class="font-weight-600 text-h6 text-uppercase text-muted ls-2 mt-8 d-block"
                >Name</span
              >
              <div class="font-weight-600 text-h3 text-typo mb-4">
                John Snow
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" class="py-0">
          <vector-map-card></vector-map-card>
          <v-card class="card-shadow bg-gradient-primary mt-6">
            <span></span>
            <v-card-text class="card-padding card-border-bottom">
              <v-row>
                <v-col>
                  <v-img
                    src="@/assets/img/cards/mastercard.png"
                    max-width="48"
                    height="30"
                  ></v-img>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-btn
                    elevation="0"
                    x-small
                    :ripple="false"
                    class="text-uppercase badge-success ls-0 font-weight-600"
                    min-width="64"
                    height="28"
                    color="#b0eed3"
                    >Active</v-btn
                  >
                </v-col>
              </v-row>
              <span
                class="font-weight-600 text-h6 text-uppercase text-light ls-2 mt-8 d-block"
                >Card number</span
              >
              <div class="text-h1 text-white font-weight-600">
                4358 <span class="px-4">-</span> 7421
                <span class="px-4">-</span> 9256
                <span class="px-4">-</span> 6682
              </div>

              <v-row>
                <v-col>
                  <span
                    class="font-weight-600 text-h6 text-uppercase text-light ls-2 mt-8 d-block"
                    >Name</span
                  >
                  <div class="font-weight-600 text-h3 text-white mb-2">
                    John Snow
                  </div>
                </v-col>
                <v-col>
                  <span
                    class="font-weight-600 text-h6 text-uppercase text-light ls-2 mt-8 d-block"
                    >Expiry Date</span
                  >
                  <div class="font-weight-600 text-h3 text-white mb-2">
                    11/23
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow my-6 bg-gradient-default">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    Total Traffic
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">350,897</p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar color="bg-white">
                    <v-icon size="20" class="text-default">
                      ni-active-40
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p
                class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
              >
                <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                <span class="text-white me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow bg-gradient-primary mb-6">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    New Users
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">2,356</p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar color="bg-white">
                    <v-icon size="20" class="text-default"> ni-atom </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p
                class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
              >
                <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                <span class="text-white me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow bg-gradient-danger mb-6">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    Performance
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">49,65%</p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar color="bg-white">
                    <v-icon size="20" class="text-default">
                      ni-spaceship
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p
                class="mt-4 mb-0 text-white font-weight-light d-flex align-center"
              >
                <v-icon class="text-white me-1">fa fa-arrow-up</v-icon>
                <span class="text-white me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>

          <to-do-list></to-do-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import CardGetStarted from "../Components/Cards/CardGetStarted.vue";
import MembersCardSearch from "./Widgets/MembersCardSearch.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ProgressTrack from "./Widgets/ProgressTrack.vue";
import VectorMapCard from "./Widgets/VectorMapCard.vue";
import ToDoList from "./Widgets/ToDoList.vue";
const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();

export default {
  name: "widgets",
  components: {
    HeaderTopDashboard,
    CardGetStarted,
    MembersCardSearch,
    FullCalendar,
    ProgressTrack,
    VectorMapCard,
    ToDoList,
  },
  data() {
    let monthText = m + 1 > 10 ? m + 1 : `0${m + 1}`;
    let yearAndMonth = `${y}-${monthText}`;
    return {
      switche: true,
      miniCards: [
        {
          subtitle: "Total Traffic",
          title: "350,897",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "New Users",
          title: "2,356",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "Sales",
          title: "924",
          gradient: "bg-gradient-success",
          icon: "ni-money-coins",
        },
        {
          subtitle: "Performance",
          title: "49,65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
      messages: [
        {
          img: require("@/assets/img/team-1.jpg"),
          name: "Tim",
          time: "2 hrs ago",
          title: "New order for Vuetify Argon",
        },
        {
          img: require("@/assets/img/team-2.jpg"),
          name: "Mike",
          time: "1 day ago",
          title: "Your theme has been updated",
        },
      ],
      timeline: [
        {
          title: "New message",
          color: "#b0eed3",
          iconColor: "#1aae6f",
          icon: "ni-bell-55",
          description: "Let's meet at Starbucks at 11:30. Wdyt?",
        },
        {
          title: "Product Issue",
          color: "#fdd1da",
          iconColor: "#f80031",
          icon: "ni-html5",
          description: "A new issue has been reported for Argon.",
        },
        {
          title: "New Likes",
          color: "#aaedf9",
          iconColor: "#03acca",
          icon: "ni-like-2",
          description: "Your posts have been liked a lot.",
        },
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        contentHeight: "auto",
        headerToolbar: false,
        events: [
          {
            title: "Call with Dave",
            start: `${yearAndMonth}-18`,
            end: `${yearAndMonth}-18`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Lunch meeting",
            start: `${yearAndMonth}-21`,
            end: `${yearAndMonth}-22`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "All day conference",
            start: `${yearAndMonth}-29`,
            end: `${yearAndMonth}-29`,
            className: "bg-green",
            allDay: true,
          },
          {
            title: "Meeting with Mary",
            start: `${yearAndMonth}-01`,
            end: `${yearAndMonth}-01`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Winter Hackaton",
            start: `${yearAndMonth}-03`,
            end: `${yearAndMonth}-03`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Digital event",
            start: `${yearAndMonth}-07`,
            end: `${yearAndMonth}-09`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "Marketing event",
            start: `${yearAndMonth}-10`,
            end: `${yearAndMonth}-10`,
            className: "bg-purple",
            allDay: true,
          },
          {
            title: "Dinner with Family",
            start: `${yearAndMonth}-19`,
            end: `${yearAndMonth}-19`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Black Friday",
            start: `${yearAndMonth}-23`,
            end: `${yearAndMonth}-23`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Cyber Week",
            start: `${yearAndMonth}-02`,
            end: `${yearAndMonth}-02`,
            className: "bg-yellow",
            allDay: true,
          },
        ],
      },
    };
  },
};
</script>
