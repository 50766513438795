<template>
  <v-card class="card-shadow mb-6">
    <v-img height="413" src="@/assets/img/img-1-1000x900.jpg"></v-img>
    <v-card-text class="card-padding">
      <h2 class="text-h2 text-typo font-weight-600 mb-0">
        Get started with Argon
      </h2>
      <small class="text-muted">by John Snow on Oct 29th at 10:23 AM</small>
      <p class="font-size-root font-weight-light text-body mt-6 mb-6">
        Argon is a great free UI package based on Bootstrap 4 that includes the
        most important components and features.
      </p>
      <a
        href="javascript:;"
        class="text-decoration-none text-primary text-body-2 ls-0 font-weight-600"
        target="_blank"
        >View Article</a
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-get-started",
};
</script>
